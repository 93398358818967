import React from "react";
// alarmanlagen - haus.js;
// import {useRef} from 'react';
import Alarmanlagencomp from "../components/Alarmanlagencomp";
import Howto2steps from "../components/Howto2steps";
import SevenDaysBanner from "../components/SevenDaysBanner";
import Footer from "../components/Footer";
import Header from "../components/Header";
import DefaultHero from "../components/DefaultHero";
import { graphql, Link } from "gatsby";
import GoogleAnalytics from "../helper/GoogleAnalytics";

const HeroElement = () => {
    let currentUrl = "";
    if (typeof window !== `undefined`) {
        currentUrl = window.location.href.replaceAll("/", "");
    }
    const onLinkClick = () => {
        console.log("link clicked");
        //  window.dataLayer.push({
        //      event: eventAction,
        //      send_to: process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID,
        //      event_category: eventCategory,
        //      event_label: eventLabel,
        //  });
        let ga = new GoogleAnalytics();
        ga.sendGTMEvent("goto_preisrechner", "/tuerschloesser");
        console.log("event is fired");
    };
    return (
        <div>
            <div
                className="container has-text-centered alm-header"
                style={{ marginTop: "-29px" }}
            >
                <div>
                    <div className="product homepage haus-hero-mt">
                        <h1
                            className="top"
                            style={{
                                // padding: "0px!important",
                                paddingBottom: "0px",
                            }}
                        >
                            Professionelle Alarmanlagen <br />
                            für Ihr Haus
                        </h1>
                        <br />
                        {/* <a className="button preisrechner-btn preisrechner-btn-primary" onClick={handleClick}>Jetzt Rabatt sichern!
                                </a> */}
                        <span
                            className="middle"
                            // style={{ paddingBottom: "0px" }}
                        >
                            Sicher. Hochwertig. Professionell.
                        </span>
                        <div className="cta-btn ">
                            <Link
                                id="btn_bighero_preisberechnen"
                                className="button preisrechner-btn preisrechner-btn-primary"
                                to={`/preisrechner`}
                            >
                                Kostenlos Preis berechnen
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const alarmanlagenhaus = ({ data, pageContext }) => {
    console.log(" alarmanlagenhaus layout fired with ", Math.random() * 1000);

    console.log("data", data);
    console.log("pageContext", pageContext);
    const contextData = {
        title: data.site.siteMetadata.title,
        image: "/img/hero/Big_Hero_Alarmanlagen-Haus.jpg",
        image768: "/img/hero/Big_Hero_Alarmanlagen-Haus.jpg",
        image1024: "/img/hero/Big_Hero_Alarmanlagen-Haus.jpg",
        image1216: "/img/hero/Big_Hero_Alarmanlagen-Haus.jpg",
        image1408: "/img/hero/Big_Hero_Alarmanlagen-Haus.jpg",
        defaultCssClass: "default-hero-not-home",
        showPriceCalculator: false,
        showHeroMobile: true,
        showBottom: true,
        showElement: <HeroElement />,
        onlyDesktop: "onlyDesktop",
    };
    return (
        <div>
            <Header
                page={data.site.siteMetadata.alamranlagenMetadata}
                siteMetadata={data.site.siteMetadata}
                contextData={{}}
            />
            <div className="section alarmlagen-haus">
                <div className="hero default-hero">
                    <div>
                        <DefaultHero context={contextData} />
                    </div>
                    {/* <div className=" hero-image img backgroundIMGhero"> */}
                    {/* <picture>
                            <source
                                media="(max-width:768px)"
                                srcSet=""/img/hero/Big_Hero_Alarmanlagen-Haus.jpg""
                            />
                            <source
                                media="(max-width:1024px)"
                                srcSet=""/img/hero/Big_Hero_Alarmanlagen-Haus.jpg""
                            />
                            <source
                                media="(max-width:1216px)"
                                srcSet=""/img/hero/Big_Hero_Alarmanlagen-Haus.jpg""
                            />
                            <source
                                media="(min-width:1217px)"
                                srcSet=""/img/hero/Big_Hero_Alarmanlagen-Haus.jpg""
                                vb
                            />
                            <img
                                src=""/img/hero/Big_Hero_Alarmanlagen-Haus.jpg""
                                style={{ width: "auto" }}
                                alt="MeinAlarm24: Deutschlands Nr.1 für Sicherheitstechnik"
                                title="MeinAlarm24: Deutschlands Nr.1 für Sicherheitstechnik"
                            />
                        </picture> */}
                    {/* </div> */}
                    {/* <div className="hero-body">
                        <div className="container has-text-centered">
                            <div className="hero-box">
                                <div className="product homepage">
                                    <h1 className="top">
                                        Professionelle Alarmanlagen <br />
                                        für Ihr Haus
                                    </h1>
                                    <br />
                                    
                                    <span className="middle">
                                        Sicher. Hochwertig. Professionell.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <Alarmanlagencomp />
            <div className="section how-to2">
                <div className="body">
                    <div
                        className="head-font"
                        style={{ paddingBottom: "0%!important" }}
                    >
                        <h2>Lassen Sie jetzt Ihr Zuhause absichern.</h2>
                        <Howto2steps />
                    </div>
                </div>
            </div>

            {/* <div
                className="section how-to2"
                style={{
                    // fontSize: "1.5rem",
                    fontFamily: '"Font Awesome 5 Free", "Source Sans Pro"',
                    color: "#193351",
                }}
            >
                <h2 className="head-font">
                    Advantages of alarm systems - how they help with burglary
                </h2>
                <p>
                    Protection In recent years, the number of burglaries and
                    burglaries in Germany has increased. It is no longer just a
                    case of opportunistic offenders or individual offenders. But
                    you don't have to put up with the fate of thieves, even if
                    they are professionals. Professionalizing the security of
                    your property is the better option. If you want to travel
                    with peace of mind, you should consider installing an alarm
                    system in your home.
                </p>
                <ul class="spaces">
                    <li>
                        Alarm systems act as a deterrent Alarm systems have a
                        deterrent effect that should not be neglected. Alarm
                        systems that are visible from the outside can deter
                        criminals from attempted break-ins. Preventive
                        protection prevents damage to the entrances by
                        effectively preventing break-ins.
                    </li>
                    <li>
                        Alarm systems deter burglars An attempted break-in is
                        usually the trigger for an alarm. The thieves are
                        primarily interested in being as quiet as possible so as
                        not to attract attention. When an alarm goes off,
                        burglars usually flee quickly. Once you're there, try to
                        remember as much information as you can about the
                        fleeing burglar and later report it to the police.
                    </li>
                    <li>
                        Alarm systems attract the attention of the neighbors The
                        alarm system usually wakes up the neighbors when it
                        sounds. Often even passers-by can recognize the alarm
                        system and initiate the necessary steps (e.g. call the
                        police). If you are in the house at this time, you will
                        be notified immediately by the alarm system.
                    </li>
                    <li>
                        Easy to install alarm systems If the installation is
                        carried out professionally, the commissioning of an
                        alarm system can be carried out quickly and easily.
                        Larger interventions in the facade or the building
                        structure are rarely necessary. Modern alarm systems can
                        also be operated wirelessly by radio, so that there is
                        no need to lay expensive cables. Wired solutions are
                        also possible if required. The installation of your
                        alarm system should be carried out by a competent and
                        experienced company. This way you are more secure and
                        get a quality installation the first time. Feel free to
                        contact us!
                    </li>
                    <li>
                        Modern alarm systems are multifunctional Modern alarm
                        systems can be multifunctional thanks to modern
                        communication technology. The alarm system immediately
                        triggers an emergency call and notifies the police and
                        the security service. Help will be on site in a matter
                        of seconds. Reliable alarm systems can also detect fire,
                        smoke and water. With a modern alarm system, you get a
                        comprehensive security solution that protects your
                        family and property.
                    </li>
                </ul>
            </div> */}
            <SevenDaysBanner cssclassName="with-background" />
            <Footer />
        </div>
    );
};

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                keywords
                description
                headerImage
                ogTitle
                ogDescription
                image
                image768
                image1024
                image1216
                image1408
                alamranlagenMetadata {
                    title
                    keywords
                    description
                    ogTitle
                    ogDescription
                }
            }
        }
    }
`;
export default alarmanlagenhaus;
