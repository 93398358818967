import React from "react";
import Alarmlagencompbox from "../components/Alarmlagencompbox";
import Alarmlagencompbox2 from "../components/Alarmlagencompbox2";
import PriceCalculationButton from "./PriceCalculator/PriceCalculationButton";

const details = [
    { cstyle1: "container-l has-text-centered" },
    {
        cstyle1: "container-r bg-color has-text-centered",
        title: "Welche Komponenten sind für eine zuverlässige Alarmanlage für ein Einfamilienhaus erforderlich?",
        description: <Alarmlagencompbox />,
    },
    {
        cstyle1: "container2-l has-text-centered",
        title: "Welche zusätzlichen Komponenten erweitern die Sicherheit einer Alarmanlage für ein Wohnhaus?",
        description: <Alarmlagencompbox2 />,
    },
    {
        cstyle1: "container2-r has-text-centered",
        description: <Buttons1 className="btnbottom" />,
    },
    {
        cstyle1: "container3-l has-text-centered",
        description: <Buttons2 className="btnbottom" />,
    },
    {
        cstyle1: "container3-r bg-color has-text-centered",
        title: "Wie wird Ihr Einfamilienhaus von MeinAlarm24 abgesichert?",
        description:
            "Eine professionelle Alarmanlage - auch Einbruchmeldeanlage genannt - wird in verschiedene Schutzschichten unterteilt, um maximale Sicherheit zu erreichen. Die Zentrale agiert dabei als Gehirn einer Alarmanlage. Die weitere Absicherung erfolgt von Innen nach Außen in mindestens zwei Schutzschichten:",
        description1: (
            <ul className="spaces" style={{ marginTop: "-18px" }}>
                <li>
                    <b>Innenraum</b>: Absicherung durch Innenbewegungsmelder zur
                    Detektion von Bewegungen im Haus. Optional mit Fotokamera
                    und tierimmun.
                </li>
                <li>
                    <b>Außenhaut</b>: Im eigenen Haus sicher fühlen und durch
                    Tür- und Fenstersensoren sowie Glasbruchmelder beim
                    Aufhebeln einer Tür oder Einschlagen eines Fensters
                    alarmiert werden
                </li>
                <li>
                    <b>Grundstück</b>: Mit Hilfe von Außenbewegungsmeldern sowie
                    optional einer Videoüberwachungsanlage Bewegungen bereits
                    vor dem Haus erfassen
                </li>
            </ul>
        ),
        description2: (
            <div>
                Über das Bedienteil oder via App können alle Schutzschichten
                separat von einander scharf und/oder unscharf geschaltet werden.
                Welche spezifischen Komponenten für Ihr Vorhaben notwendig und
                sinnvoll sind, prüfen unsere professionellen Sicherheitsberater
                beim kostenlosen Sicherheitscheck bei Ihnen vor Ort.
            </div>
        ),
    },
    {
        cstyle1: "container4-l has-text-centered",
        title: "Was passiert im Falle eines Alarms in oder an meinem Haus?",
        description:
            "Grundsätzlich erfassen unsere Alarmsysteme unterschiedliche Arten von Alarmen, zu denen neben Einbruchalarm, Sabotagealarm und Überfallalarm auch Gefahrenalarme wie z.B. Brand-, Wasser-, oder Kohlenmonoxidalarm zählen.",
        description1:
            "Bei jeder dieser Arten erfolgt eine Alarmierung an verschiedenen Stellen, um für den Ernstfall gewappnet zu sein:",
        description2: (
            <ul className="spaces">
                <li>
                    <b>Lokale Alarmierung:</b> Durch das Auslösen der Innen- und
                    Außensirene werden Personen im Haus, aber auch Nachbarn
                    alarmiert und der Einbrecher durch die akustischen Signale
                    in die Flucht geschlagen
                </li>
                <li>
                    <b>Alarmierung der Bewohner:</b> Weiterhin wird sofort eine
                    Push-Benachrichtigung auf alle Smartphones gesendet, auf
                    denen die App eingerichtet ist
                </li>
                <li>
                    <b>Alarmierung zur Intervention:</b> Sofern die Alarmanlage
                    aufgeschaltet ist, wird zusätzlich unsere Notrufleitstelle
                    benachrichtigt, die dann einen festgelegten Plan verfolgt.
                    Sollten Sie den Alarm nicht entschärfen, wird auch die
                    Polizei oder unser Sicherheitsdienst informiert, um im
                    Ernstfall keine Sekunde Zeit zu verlieren
                </li>
            </ul>
        ),
    },

    { cstyle1: "container4-r has-text-centered" },
];

const detailsM = [
    { cstyle1: "container-l has-text-centered" },
    {
        cstyle1: "container-r bg-color has-text-centered",
        title: "Welche Komponenten sind für eine zuverlässige Alarmanlage für ein Einfamilienhaus erforderlich?",
        description: <Alarmlagencompbox />,
    },
    {
        cstyle1: "container2-r has-text-centered",
        description: <Buttons1 className="btnbottom" />,
    },
    {
        cstyle1: "container2-l has-text-centered",
        title: "Welche zusätzlichen Komponenten erweitern die Sicherheit einer Alarmanlage für ein Wohnhaus?",
        description: <Alarmlagencompbox2 />,
    },
    {
        cstyle1: "container3-l has-text-centered",
        description: <Buttons2 className="btnbottom" />,
    },
    {
        cstyle1: "container3-r bg-color has-text-centered",
        title: "Wie wird Ihr Einfamilienhaus von MeinAlarm24 abgesichert?",
        description:
            "Eine professionelle Alarmanlage - auch Einbruchmeldeanlage genannt - wird in verschiedene Schutzschichten unterteilt, um maximale Sicherheit zu erreichen. Die Zentrale agiert dabei als Gehirn einer Alarmanlage. Die weitere Absicherung erfolgt von Innen nach Außen in mindestens zwei Schutzschichten:",
        description1: (
            <ol className="spaces">
                <li>
                    Innenraum: Absicherung durch Innenbewegungsmelder zur
                    Detektion von Bewegungen im Haus. Optional mit Fotokamera
                    und tierimmun.
                </li>
                <li>
                    Außenhaut: Im eigenen Haus sicher fühlen und durch Tür- und
                    Fenstersensoren sowie Glasbruchmelder beim Aufhebeln einer
                    Tür oder Einschlagen eines Fensters alarmiert werden
                </li>
                <li>
                    Grundstück: Mit Hilfe von Außenbewegungsmeldern sowie
                    optional einer Videoüberwachungsanlage Bewegungen bereits
                    vor dem Haus erfassen
                </li>
            </ol>
        ),
        description2:
            "Über das Bedienteil oder via App können alle Schutzschichten separat von einander scharf und/oder unscharf geschaltet werden. Welche spezifischen Komponenten für Ihr Vorhaben notwendig und sinnvoll sind, prüfen unsere professionellen Sicherheitsberater beim kostenlosen Sicherheitscheck bei Ihnen vor Ort.",
    },
    { cstyle1: "container4-r has-text-centered" },
    {
        cstyle1: "container4-l has-text-centered",
        title: "Was passiert im Falle eines Alarms in oder an meinem Haus?",
        description:
            "Grundsätzlich erfassen unsere Alarmsysteme unterschiedliche Arten von Alarmen, zu denen neben Einbruchalarm, Sabotagealarm und Überfallalarm auch Gefahrenalarme wie z.B. Brand-, Wasser-, oder Kohlenmonoxidalarm zählen.",
        description1:
            "Bei jeder dieser Arten erfolgt eine Alarmierung an verschiedenen Stellen, um für den Ernstfall gewappnet zu sein:",
        description2: (
            <ul className="spaces">
                <li>
                    <b>Lokale Alarmierung:</b> Durch das Auslösen der Innen- und
                    Außensirene werden Personen im Haus, aber auch Nachbarn
                    alarmiert und der Einbrecher durch die akustischen Signale
                    in die Flucht geschlagen
                </li>
                <li>
                    <b>Alarmierung der Bewohner:</b> Weiterhin wird sofort eine
                    Push-Benachrichtigung auf alle Smartphones gesendet, auf
                    denen die App eingerichtet ist
                </li>
                <li>
                    <b>Alarmierung zur Intervention:</b> Sofern die Alarmanlage
                    aufgeschaltet ist, wird zusätzlich unsere Notrufleitstelle
                    benachrichtigt, die dann einen festgelegten Plan verfolgt.
                    Sollten Sie den Alarm nicht entschärfen, wird auch die
                    Polizei oder unser Sicherheitsdienst informiert, um im
                    Ernstfall keine Sekunde Zeit zu verlieren
                </li>
            </ul>
        ),
    },
];

function Buttons1() {
    return (
        <div className="columns">
            <div className="column has-text-centered">
                <a
                    id="btn_termin_case1"
                    className="button preisrechner-btn preisrechner-btn-primary"
                    href="/beratungstermine/"
                >
                    Kostenloses Erstgespräch vereinbaren
                </a>
            </div>
        </div>
    );
}

function Buttons2() {
    return (
        <div className="columns">
            <div className="column has-text-centered">
                <PriceCalculationButton
                    id="btn_preisrechner_case2"
                    text="Mein Objekt absichern lassen"
                />
            </div>
        </div>
    );
}

function Containers(props) {
    return (
        <div className="column is-half">
            <div className={props.details.cstyle1}>
                <h2>{props.details.title}</h2>
                <div className="container">
                    <p className="description">
                        {props.details.description}
                        <br />
                        <br />
                        {props.details.description1}
                        <br />
                        {props.details.description2}
                    </p>
                </div>
            </div>
        </div>
    );
}

function ContainersM(props) {
    return (
        <div className="column is-half">
            <div className={props.detailsM.cstyle1}>
                <h2>{props.detailsM.title}</h2>
                <div className="container">
                    <p className="description">
                        {props.detailsM.description}
                        <br />
                        <br />
                        {props.detailsM.description1}
                        <br />
                        {props.detailsM.description2}
                    </p>
                </div>
            </div>
        </div>
    );
}

const Alarmanlagencomp = () => {
    return (
        <div className="alarmanlagencomp">
            <div className="content-desktop only-desktop">
                <div className="columns is-multiline">
                    {details.map((detail) => (
                        <Containers details={detail} />
                    ))}
                </div>
            </div>
            <div className="content-mobile only-mobile">
                {detailsM.map((detail) => (
                    <ContainersM detailsM={detail} />
                ))}
            </div>
        </div>
    );
};

export default Alarmanlagencomp;
